<template>
    <div>
        <Navbar></Navbar>
        <div class="about-banner ">
            <p data-aos="fade-up">We are Nuba - the digital backbone of restaurants, the future of gastronomy, and the
                reliable partner of
                local businesses</p>
            <div class="nuba-logo" data-aos="fade-up">
                <img src="@/assets/images/Nuba-bg.png" class="img-fluid" alt="nubba">
            </div>
        </div>
        <div class="promotion-banne container">




            <!-- slider start -->
            <div id="carouselExampleCaptions" class="carousel slide about-slide margin-top-100" data-bs-ride="carousel">
                <div class="carousel-indicators custom-indicator">
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"
                        aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
                        aria-label="Slide 2"></button>
                    <!-- <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"
                        aria-label="Slide 3"></button> -->
                </div>
                <div class="carousel-inner">
                    <!-- slide 1 -->
                    <div class="carousel-item active">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="promotion-img" data-aos="fade-right">
                                    <img src="@/assets/images/table-resturant.png" class="img-fluid" alt="">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div style="margin-top: 10px;" class="margin-top ms-5" data-aos="fade-left">
                                    <div class="get-clients-btn">
                                        <div class="user-iconbox">
                                            <i class="fa-solid fa-user-group"></i>
                                        </div>

                                        <p style="margin-top: 5px;" class="get-client ">Get clients</p>
                                    </div>
                                    <h4 class="client-title text-light">Get new clients</h4>
                                    <p class="client-details">From online waitlists to accurate turn times,
                                        set record-breaking nights, every time with
                                        a table management system for restaurants.</p>

                                    <div @click="showModal" class="yellow-button">
                                        Start for free
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row flex-direction">

                            <div class="col-md-6">
                                <div style="margin-top: 40px;" class="margin-top" data-aos="fade-left">
                                    <div class="get-clients-btn">
                                        <div class="user-iconbox">
                                            <i class="fa-solid fa-user-group"></i>
                                        </div>

                                        <p style="margin-top: 5px;" class="get-client ">Get clients</p>
                                    </div>
                                    <h4 class="client-title text-light">Get new clients</h4>
                                    <p class="client-details">From online waitlists to accurate turn times,
                                        set record-breaking nights, every time with
                                        a table management system for restaurants.</p>

                                    <div @click="showModal" class="yellow-button">
                                        Start for free
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="promotion-img" data-aos="fade-right">
                                    <img src="@/assets/images/table-resturant.png" class="img-fluid" alt="">
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Slider-2 -->
                    <div class="carousel-item ">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="promotion-img" data-aos="fade-right">
                                    <img src="@/assets/images/table-resturant.png" class="img-fluid" alt="">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div style="margin-top: 10px;" class="margin-top ms-5" data-aos="fade-left">
                                    <div class="get-clients-btn">
                                        <div class="user-iconbox">
                                            <i class="fa-solid fa-user-group"></i>
                                        </div>

                                        <p style="margin-top: 5px;" class="get-client ">Get clients</p>
                                    </div>
                                    <h4 class="client-title text-light">Get new clients</h4>
                                    <p class="client-details">From online waitlists to accurate turn times,
                                        set record-breaking nights, every time with
                                        a table management system for restaurants.</p>

                                    <div @click="showModal" class="yellow-button">
                                        Start for free
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row flex-direction">

                            <div class="col-md-6">
                                <div style="margin-top: 40px;" class="margin-top" data-aos="fade-left">
                                    <div class="get-clients-btn">
                                        <div class="user-iconbox">
                                            <i class="fa-solid fa-user-group"></i>
                                        </div>

                                        <p style="margin-top: 5px;" class="get-client ">Get clients</p>
                                    </div>
                                    <h4 class="client-title text-light">Get new clients</h4>
                                    <p class="client-details">From online waitlists to accurate turn times,
                                        set record-breaking nights, every time with
                                        a table management system for restaurants.</p>

                                    <div @click="showModal" class="yellow-button">
                                        Start for free
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="promotion-img" data-aos="fade-right">
                                    <img src="@/assets/images/table-resturant.png" class="img-fluid" alt="">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button> -->

            </div>

            <!-- Promo card section -->
            <div class="promo-section" data-aos="fade-up">
                <h6>We all love food</h6>
                <p class="b-title w-100"> Let’s love efficiency & simplicity too. </p>
            </div>
            <div class="d-flex margin-top-80" data-aos="fade-up">
                <div class="promo-card card1">
                    <div class="card-icon">
                        <img src="@/assets/icons/coin.png" alt="">
                    </div>
                    <h5>Sales Optimisation</h5>
                    <p class="small">Discounts can encourage customers to choose your restaurant on less busy days,
                        which can help
                        optimize the use of your resources and maximize your revenue.</p>

                </div>
                <!-- card-2 -->
                <div class="promo-card  card1">
                    <div class="card-icon">
                        <img src="@/assets/icons/coin.png" alt="">
                    </div>
                    <h5>Sales Optimisation</h5>
                    <p class="small">Discounts can encourage customers to choose your restaurant on less busy days,
                        which can help
                        optimize the use of your resources and maximize your revenue.</p>

                </div>
                <!-- card-3 -->
                <div class="promo-card card1">
                    <div class="card-icon">
                        <img src="@/assets/icons/coin.png" alt="">
                    </div>
                    <h5>Sales Optimisation</h5>
                    <p class="small">Discounts can encourage customers to choose your restaurant on less busy days,
                        which can help
                        optimize the use of your resources and maximize your revenue.</p>

                </div>
                <!-- card-4 -->
                <div class="promo-card card1">
                    <div class="card-icon">
                        <img src="@/assets/icons/coin.png" alt="">
                    </div>
                    <h5>Sales Optimisation</h5>
                    <p class="small">Discounts can encourage customers to choose your restaurant on less busy days,
                        which can help
                        optimize the use of your resources and maximize your revenue.</p>

                </div>
                <!-- card-5 -->
                <div class="promo-card card1">
                    <div class="card-icon">
                        <img src="@/assets/icons/coin.png" alt="">
                    </div>
                    <h5>Sales Optimisation</h5>
                    <p class="small">Discounts can encourage customers to choose your restaurant on less busy days,
                        which can help
                        optimize the use of your resources and maximize your revenue.</p>

                </div>
                <!-- card-6 -->
                <div class="promo-card card1">
                    <div class="card-icon">
                        <img src="@/assets/icons/coin.png" alt="">
                    </div>
                    <h5>Sales Optimisation</h5>
                    <p class="small">Discounts can encourage customers to choose your restaurant on less busy days,
                        which can help
                        optimize the use of your resources and maximize your revenue.</p>

                </div>
            </div>


            <!-- Get start section -->
            <section class="get-started">
                <p class="b-title text-center"> Start using Nuba today</p>
                <div class="d-flex justify-content-center">
                    <div @click="showModal" class="yellow-button button">Start for free</div>
                </div>
                <div class="tab-image">
                    <img src="@/assets/images/tablet.png" data-aos="zoom-out" class="img-fluid" alt="tablet">
                </div>

            </section>

            <ContactUs id="contact_us"></ContactUs>
            <b-modal body-class="p-0" v-model="isModalVisible" size="xl" centered hide-footer hide-header
                content-class="modal-style">
                <RequestModal />
            </b-modal>
        </div>
    </div>
</template>
<script>
import RequestModal from '../modals/RequestModal.vue';
import Navbar from '@/components/header/Navbar.vue';
import ContactUs from './ContactUs.vue';
import AOS from 'aos';
import 'aos/dist/aos.css';
export default {
    data() {
        return {
            isModalVisible: false,
        }
    },
    components: {
        Navbar,
        RequestModal,
        ContactUs
    },
    methods: {

        showModal() {
            this.isModalVisible = true;
        },
        closeModal() {
            this.isModalVisible = false;
        },
    },
    mounted() {
        AOS.init({
            duration: 800, // Animation duration
            offset: 200, // Offset (in px) from the original trigger point
            easing: 'ease', // Easing type
            once: false // Whether animation should only happen once
        });
    },
}
</script>
<style>
@import '/src/assets/css/common.css';

.about-slide .custom-indicator {
    height: 31px;
    bottom: -40px;
    right: 163px;
}
.about-slide .yellow-button{
    font-size: 16px;
    margin-top: 15px;
}
.go-corner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 32px;
    height: 32px;
    overflow: hidden;
    top: 0;
    right: 0;
    background-color: #151413;
    border-radius: 0 4px 0 32px;
}

.go-arrow {
    margin-top: -4px;
    margin-right: -4px;
    color: white;
    font-family: courier, sans;
}

.card1 {
    display: block;
    position: relative;
    max-width: 500px;
    width: 100%;
    background-color: #21201f;
    border-radius: 4px;
    padding: 32px 24px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
}

.card1:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -12px;
    right: -18px;
    background: #151413;
    height: 42px;
    width: 42px;
    border-radius: 37px;
    transform: scale(3);
    transform-origin: 50% 50%;
    transition: 0.6s ease-in-out;
}

.card1:hover:before {
    transform: scale(36);
}

.card1:hover {
    border: 1px solid #b58b46;
    transition: 0.6s ease-in-out;
}

.card1:hover p {
    transition: all 0.3s ease-out;
    color: white;
}

.card1:hover h3 {
    transition: all 0.3s ease-out;
    color: #ffffff;
}

.promotion-img {
    width: 470px;
    height: 290px;
}

.promotion-img img {
    height: 100%;
    width: 100%;
}

.tab-image {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
}

.get-started .button {
    width: 190px;
}

.promo-card {
    padding: 40px;
    border-radius: 24px;
    background-color: #21201f;
    max-width: 400px;
    width: 100%;
    border: 1px solid;
}

.margin-top-80 {
    margin-top: 80px;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 100px;
}

.promo-card h5 {
    margin-top: 70px;
    font-size: 32px;
    font-weight: 400;
    color: white;
    font-family: 'Epilogue Regular';
}

.promo-card p {
    font-family: 'Epilogue Regular';
    color: #808080;
    margin-top: 10px;
    font-size: 18px;
    line-height: 1.3;
}

.card-icon {
    height: 66px;
    width: 66px;
}

.card-icon img {
    height: 100%;
    width: 100%;
}

.promo-section {
    margin-top: 100px;
}

.promo-section h6 {
    font-size: 40px;
    font-weight: 400;
    color: #808080;
}

.margin-top-100 {
    margin-top: 200px;
}

.promotion-banne {
    margin-top: 200px;
}

.banner-restuar {
    display: flex;
    justify-content: center;
}

.about-banner {
    margin-top: 40px;
}

.about-banner p {
    font-family: 'Epilogue Regular';
    color: white;
    font-size: 20px;
    width: 30%;
    margin-bottom: 0;
    line-height: 31px;
}

/* .nuba-logo {
    z-index: 9;
    position: relative;
    background-image: url(@/assets/images/Nuba-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 550px;
} */
.nuba-logo {
    height: 550px;
    width: 100%;
}

.nuba-logo img {
    height: 100%;
    width: 100%;
    margin: 0 auto;
}

.banner-restuar img {
    margin-top: -50px;
    z-index: 0;
}

.get-clients-btn img {
    height: 32px;
    width: 32px;
}

.get-clients-btn p {
    margin: 0;
    margin-left: 12px;
    color: #B68D40;
    font-size: 20px;
    line-height: 26px;
}

.client-title {
    font-size: 30px;
    line-height: 55.9px;
    margin: 0;
    margin-top: 27px;
    margin-left: 0;
    text-align: left;
    font-family: 'Epilogue Regular';
}

.client-details {
    font-size: 18px;
    line-height: 28px;
    color: rgba(255, 255, 255, 0.5);
    text-align: start;
    font-family: 'Epilogue Regular';
}

.user-iconbox {
    background-color: #B68D40;
    height: 32px;
    width: 32px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.get-client {
    font-size: 43px;
    line-height: 55.9px;
    margin: 0;
    margin-top: 27px;
    margin-left: 0;
    color: white;
    text-align: left;
    font-family: 'Epilogue Regular';
}

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
    .promotion-img {
        width: auto;
        height: auto;
    }

    .margin-top {
        margin-top: 0 !important;
    }
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
    .promotion-img {
        height: 100%;
        width: 100%;
    }

    .nuba-logo {
        background-size: contain;
        height: 50vh;
    }

    .margin-top-80 {
        flex-wrap: wrap;
        gap: 5px;
        margin-bottom: 60px;
    }

    .promotion-img img {
        height: auto;
    }

    .client-title {
        margin-top: 10px !important;
        font-size: 26px !important;
    }

    .client-details {
        font-size: 15px;
        line-height: 1.6 !important;
    }

    .promo-card {
        max-width: 315px;
        margin: 7px;
    }

    t.ab-image {
        margin-top: 20px;
    }

    .about-banner p {
        width: 70%;
    }
}

/* // X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .about-slide .custom-indicator {
    bottom: 0px;
    right: 26px;
}
    .flex-direction {
        flex-direction: column-reverse;
    }

    .promotion-img {
        height: 100%;
        width: 100%;
    }

    .nuba-logo {
        background-size: contain;
        height: 30vh;
    }

    .margin-top-80 {
        flex-wrap: wrap;
        margin-top: 20px;
        margin-bottom: 60px;
        gap: 0px;

    }

    .nuba-logo img {
        height: 100%;
        width: 100%;
    }

    .about-banner {
        margin-top: 50px;
    }

    .about-banner p {
        font-size: 16px;
        width: 100%;
        margin-bottom: 29px;
    }

    .banner-restuar img {
        height: 100%;
        width: 100%;
    }

    .promotion-img img {
        height: 100%;
        width: 100%;
    }

    .promotion-banne {
        margin-top: 10px;
    }

    .margin-top-100 {
        margin-top: 20px;
    }

    .b-title {
        font-size: 26px;
        line-height: 1.1;
    }

    .promo-section h6 {
        font-size: 28px;
    }

    .promo-section {
        margin-top: 30px;
    }

    .promo-card {
        padding: 20px;

    }

    .promo-card h5 {
        margin-top: 31px;
        font-size: 24px;
        font-weight: 400;
    }

    .tab-image {
        margin-top: 20px;
    }
}
</style>