<template>
    <div class="container-col">
        <!-- <p class="title" data-aos="fade-up">Our Partners</p>
        <div class="content" data-aos="fade-down">
            <div class="img-box">
                <img src="@/assets/images/blomins.png" alt="">
            </div>
            <div class="img-box">
                <img src="@/assets/images/ventue.png" alt="">
            </div>
            <div class="img-box">
                <img src="@/assets/images/mgm.png" alt="">
            </div>
            <div class="img-box">
                <img src="@/assets/images/ww.png" alt="">
            </div>
        </div> -->


        <div class="get-clients">
            <!-- slider start -->
            <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-indicators custom-indicator">
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"
                        aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
                        aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"
                        aria-label="Slide 3"></button>
                </div>
                <div class="carousel-inner">
                    <!-- slider-1 -->
                    <div class="carousel-item active">
                        <div class="main-row">
                            <div class="image-side">
                                <div data-aos="fade-down" class="tablet-image">
                                    <img class="img img-fluid" src="@/assets/images/tablet.png" alt="">
                                </div>
                            </div>
                            <div class="d-flex align-items-center">
                                <div class="side-detail">
                                    <div class="get-clients-btn" data-aos="fade-right">
                                        <div class="user-iconbox">
                                            <i class="fa-solid fa-user-group"></i>
                                        </div>
                                        <p class="get-client">Why Choose Nuba Book?</p>
                                    </div>
                                    <h4 class="client-title">Optimize Reservations:</h4>
                                    <p class="client-details">Say goodbye to booking errors and overbookings. Our automated
reservation system ensures smooth and efficient management of your bookings, reducing
mistakes and enhancing customer satisfaction.</p>
                                    <div @click="showModal" class="yellow-button" data-aos="fade-up">
                                        Request a Free Demo
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="main-row reverse-row">
                            <div class="d-flex align-items-center">
                                <div class="side-detail ms-5 width-50">
                                    <div class="get-clients-btn" data-aos="fade-right">
                                        <div class="user-iconbox">
                                            <i class="fa-solid fa-user-group"></i>
                                        </div>
                                        <p class="get-client">Why Choose Nuba Book?</p>
                                    </div>
                                    <h4 class="client-title">Enhance Customer Experience</h4>
                                    <p class="client-details w-100">Personalize your offers and promotions using our AI-driven
insights. Tailor your services to meet your customers' preferences and boost their loyalty.
</p>
                                    <div @click="showModal" class="yellow-button" data-aos="fade-up">
                                        Request a Free Demo
                                    </div>
                                </div>
                            </div>
                            <div class="image-side width-50 d-flex justify-content-center">
                                <div class="slider-images">
                                    <img src="@/assets/images/mobile.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- slider-2 -->
                    <div class="carousel-item">
                        <div class="main-row ">
                            <div class="image-side">
                                <div data-aos="fade-down" class="tablet-image">
                                    <img class="img img-fluid" src="@/assets/images/tablet.png" alt="">
                                </div>
                            </div>
                            <div class="d-flex align-items-center">
                                <div class="side-detail">
                                    <div class="get-clients-btn" data-aos="fade-right">
                                        <div class="user-iconbox">
                                            <i class="fa-solid fa-user-group"></i>
                                        </div>
                                        <p class="get-client">Why Choose Nuba Book</p>
                                    </div>
                                    <h4 class="client-title">Efficient Table Management</h4>
                                    <p class="client-details">Organize your tables, shifts, and schedules effortlessly.
                                        Maximize your restaurant's efficiency with our intuitive table management tools.</p>
                                    <div @click="showModal" class="yellow-button" data-aos="fade-up">
                                        Request a Free Demo
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="main-row reverse-row">
                            <div class="d-flex align-items-center">
                                <div class="side-detail ms-5 width-50">
                                    <div class="get-clients-btn" data-aos="fade-right">
                                        <div class="user-iconbox">
                                            <i class="fa-solid fa-user-group"></i>
                                        </div>
                                        <p class="get-client">Why Choose Nuba Book</p>
                                    </div>
                                    <h4 class="client-title">Powerful Marketing Tools</h4>
                                    <p class="client-details w-100">Attract more customers with targeted discounts, exciting events,
and engaging stories. Reward your loyal customers with gifts and special offers, managed
seamlessly through our platform.</p>
                                    <div @click="showModal" class="yellow-button" data-aos="fade-up">
                                        Request a Free Demo
                                    </div>
                                </div>
                            </div>
                            <div class="image-side width-50 d-flex justify-content-center">
                                <div class="slider-images">
                                    <img src="@/assets/images/mobile.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                  <!-- slider-3 -->
                  <div class="carousel-item">
                        <div class="main-row">
                            <div class="image-side">
                                <div data-aos="fade-down" class="tablet-image">
                                    <img class="img img-fluid" src="@/assets/images/tablet.png" alt="">
                                </div>
                            </div>
                            <div class="d-flex align-items-center">
                                <div class="side-detail">
                                    <div class="get-clients-btn" data-aos="fade-right">
                                        <div class="user-iconbox">
                                            <i class="fa-solid fa-user-group"></i>
                                        </div>
                                        <p class="get-client">Why Choose Nuba Book?</p>
                                    </div>
                                    <h4 class="client-title">Comprehensive Support</h4>
                                    <p class="client-details">Benefit from dedicated technical support and continuous training
                                        to ensure you get the most out of Nuba Book. Our team is always here to help you succeed.</p>
                                    <div @click="showModal" class="yellow-button" data-aos="fade-up">
                                        Request a Free Demo
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="main-row reverse-row">
                            <div class="d-flex align-items-center">
                                <div class="side-detail ms-5 width-50">
                                    <div class="get-clients-btn" data-aos="fade-right">
                                        <div class="user-iconbox">
                                            <i class="fa-solid fa-user-group"></i>
                                        </div>
                                        <p class="get-client">Get clients</p>
                                    </div>
                                    <h4 class="client-title">Get new clients</h4>
                                    <p class="client-details w-100">From online waitlists to accurate turn times,
                                        set record-breaking nights, every time with
                                        a table management system for restaurants.</p>
                                    <div @click="showModal" class="yellow-button" data-aos="fade-up">
                                        Start for free
                                    </div>
                                </div>
                            </div>
                            <div class="image-side width-50 d-flex justify-content-center">
                                <div class="slider-images">
                                    <img src="@/assets/images/mobile.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <b-modal body-class="p-0" v-model="isModalVisible" size="xl" centered hide-footer hide-header
            content-class="modal-style">
            <RequestModal />
        </b-modal>

    </div>
</template>
<script>

import RequestModal from '../modals/RequestModal.vue';

import AOS from 'aos';
import 'aos/dist/aos.css';


export default
    {
        data() {
            return {
                isModalVisible: false,
            }
        },

        components: {
            RequestModal
        },

        methods: {

            showModal() {
                this.isModalVisible = true;
            },
            closeModal() {
                this.isModalVisible = false;
            },
        },
        mounted() {
            AOS.init({
                duration: 800, // Animation duration
                offset: 200, // Offset (in px) from the original trigger point
                easing: 'ease', // Easing type
                once: false // Whether animation should only happen once
            });
        },


    }

</script>
<style>
.width-50 {
    width: 90%;
}



.client-details {
    width: 70%;
}

.main-row {
    display: flex;
    gap: 100px;
    justify-content: space-between;
    width: 100%;
}

.yellow-button {
    width: 190px;
}

.tablet-image {
    width: 410px;
    height: 258px;
}

.tablet-image img {
    height: 100%;
    width: 100%;
}
.slider-images{
    width: 196px;
    height: 383px;
}
.slider-images img{
    height: 100%;
    width: 100%;
}
/* ::-webkit-scrollbar {
    display: none;
} */

.custom-indicator {
    background: #2c2b2a;
    width: 15%;
    margin: 0 auto;
    border-radius: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    bottom: 40px;
    position: absolute;
}

.container-col {
    color: white;
    justify-content: start;
    align-items: start;
    margin-top: 180px;
}

.img-box {
    height: 94px;
    width: 283px;
}

.img-box img {
    height: 100%;
    width: 100%;
    cursor: pointer;
    object-fit: contain;
    filter: grayscale(0%);
    transition: 0.4s;
}

.img-box img:hover {
    filter: grayscale(100%);
    transform: scale(1.1);
}

.container-col p {
    font-size: 18px;
    line-height: 26.6px;
    font-weight: 300;
    overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;

}

.content {
    display: flex;
    gap: 80px;
    justify-content: space-between;
    margin-bottom: 140px;
}

.get-clients {
    border-radius: 24px;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 2rem 2rem;
}

.get-client {
    font-weight: 400 !important;
}



.mobile-img {
    height: calc(100vw /2.5);
    margin-left: 460px;
    object-fit: contain;

}

.get-clients-btn {
    display: flex;
    align-items: center;
}

.get-clients-btn img {
    height: 32px;
    width: 32px;
}

.get-clients-btn p {
    margin: 0;
    margin-left: 12px;
    color: #B68D40;
    font-size: 20px;
    line-height: 26px;
}

.client-title {
    font-size: 35px;
    line-height: 55.9px !important;
    margin: 0 !important;
    margin-top: 9px !important;
    margin-left: 0 !important;
    text-align: left;
    font-family: 'Epilogue Regular';
}

.client-details {
    font-size: 18px;
    line-height: 28px;
    color: rgba(255, 255, 255, 0.5);
    text-align: start;
    font-family: 'Epilogue Regular';
}

.user-iconbox {
    background-color: #B68D40;
    height: 32px;
    width: 32px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
    .tablet-image {
        height: 292px;
        width: 382px;
    }

    .tablet-image img {
        object-fit: initial;
    }

    .width-50 {
        width: 80%;
    }

    .get-clients {
        padding: 2rem 2rem;
    }

    .img {
        width: calc(80vw / 1.8);
    }

    .margin-top {
        margin-left: 0 !important;
    }

    .mobile-img {
        height: calc(100vw / 2.5);
        margin-left: 150px;
        margin-top: 4rem;
    }

    .custom-indicator {
        bottom: 13px;
    }

    .get-clients-btn {
        padding-top: 20px;
    }
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
    .get-clients {
        padding: 2rem 2rem;
    }

    .main-row {
        gap: 25px;
    }

    .client-details {
        width: 100%;
    }

   

    .tablet-image img {
        object-fit: initial;
    }

    .client-title {
        font-size: 26px;
        margin-top: 5px;
    }

    .width-50 {
        width: 100%;
        margin-left: 0 !important;
    }

    .custom-indicator {
        width: 35%;
        bottom: 22px;
    }

    .carousel-inner {
        padding-bottom: 5rem;
        height: 100%;
    }

    .mobile-img {
        margin-left: 0
    }

    .wrap-flex {
        flex-wrap: wrap;
    }

    .img {
        width: 100%;
    }

    .get-clients-btn {
        padding-top: 0px;
    }

    .margin-top {
        margin-top: 0px !important;

        padding: 1rem 1.7rem;
        margin-left: 0 !important;
    }
}

/* For mobile responsiveness */
@media (max-width: 575.98px) {
    .reverse-row{
        flex-direction: column-reverse;
    }
    .get-clients {
        padding: 2rem 2rem;
        height: 100%;
    }

    .main-row {
        flex-wrap: wrap;
        gap: 25px;
    }

    .client-details {
        width: 100%;
        font-size: 15px !important;
        line-height: 1.5 !important;
    }

    .tablet-image {
        height: 100%;
        width: 100%;
    }

    .tablet-image img {
        object-fit: initial;
    }

    .client-title {
        font-size: 26px !important;
        margin-top: 5px !important;
    }

    .width-50 {
        width: 100%;
        margin-left: 0 !important;
    }

    .slider-images {
        margin-top: 10px;
        height: 200px;
        width: 100px;
    }

    .img-box {
        height: 94px;
        width: 76px;
    }

    .container-col {
        margin-top: 20px;
    }

    .mobile-slide {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .content {
        gap: 0px;
        margin-bottom: 20px;
        flex-wrap: wrap;
    }

    .custom-indicator {
        width: 70%;
    }

    .mobile-img {
        margin-left: 0
    }

    .wrap-flex {
        flex-wrap: wrap;
    }

    .img {
        width: 100%;
    }

    .get-clients-btn {
        padding-top: 0px;
    }

    .margin-top {
        margin-top: 0px !important;
        padding: 1.7rem;
        margin-left: 0 !important;
    }
}
</style>