<template>
  <div class="section-margin">
    <div class="container">
      <p class="b-title">
        Key Features
    </p>
    </div>
   
    <div class="b-card-container">
      <BenifitCard imgPath="coin.png" title="Integrated CRM and Dashboard"
        subtitle="Manage all your restaurant's operations from a single,
user-friendly dashboard. Keep track of multiple locations with ease.">
      </BenifitCard>
      <BenifitCard imgPath="voucher.png" title="Brand Management"
        subtitle="Easily update your restaurant's branding with logo and photo
management. Ensure your online presence is always up-to-date">
      </BenifitCard>
      <BenifitCard imgPath="user-add.png" title="Reservation System"
        subtitle="Allow customers to book tables effortlessly. Manage availability and
streamline the booking process to enhance customer satisfaction.">
      </BenifitCard>
      <BenifitCard imgPath="wave-fill.png" title="Table and Shift Management"
        subtitle="Edit floor plans, schedule shifts, and organize tables
efficiently. Ensure optimal use of your restaurant's space and resources.
">
      </BenifitCard>
      <BenifitCard imgPath="bar_chart.png" title="Menu Management"
        subtitle="Keep your menu updated and promote special items easily. Engage
customers with dynamic menu offerings.">
      </BenifitCard>
      <BenifitCard imgPath="coin.png" title="Marketing and Promotions"
        subtitle="Create and manage discounts, plan events, share stories, and
offer gifts to individual guests or groups. Boost customer engagement and loyalty.">
      </BenifitCard>
      <BenifitCard imgPath="wave-fill.png" title="Guest Management"
        subtitle="Maintain detailed guest profiles, manage blacklists, and ensure a
smooth and personalized dining experience for your customers.
">
      </BenifitCard>
      <BenifitCard imgPath="bar_chart.png" title="Staff Management"
        subtitle="Add and manage staff members effortlessly. Control access and
streamline team management.">
      </BenifitCard>
      <BenifitCard imgPath="coin.png" title="Support and Settings"
        subtitle="Access comprehensive support and customize your restaurant's
settings, including payment options and subscriptions.">
      </BenifitCard>
    </div>
  </div>
</template>

<script>
import BenifitCard from './BenifitCard.vue';
import AOS from 'aos';
import 'aos/dist/aos.css';
export default {
  components: {
    BenifitCard
  },
  mounted() {
        AOS.init({
            duration: 800, // Animation duration
            offset: 200, // Offset (in px) from the original trigger point
            easing: 'ease', // Easing type
            once: false // Whether animation should only happen once
        });
    },
}
</script>

<style>
@import '/src/assets/css/common.css'; 
.section-margin{
  margin-top: 200px;
}
.b-title {
  font-family: 'Epilogue Regular';
    font-size: 40px;
    font-weight: 400;
    line-height: 62.4px;
    text-align: left;
    color: #fff;
    width: 86%;
    margin: 0 auto;
    padding-bottom: 30px;
}

.b-card-container {
  display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: center;
    gap: 20px;
    padding-bottom: 5rem;
}
/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px){
  .section-margin {
    margin-top: 60px;
}
.b-title {
  font-size: 30px;
}
}

/* for mobile */
@media (max-width: 575.98px) {
  .b-card-container {
  padding-bottom: 3rem;
}
.section-margin{
  margin-top: 50px;
}
.b-title{
    font-size: 36px;
    line-height: 1.1;
}
}
</style>