import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router";
import { BootstrapVue, IconsPlugin, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Terms from './layouts/Terms.vue'
import Dashboard from './components/dashboard/Dashboard.vue';
import Aboutus from './components/dashboard/Aboutus.vue';

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(BootstrapVueIcons)
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    { path: "/", component: Dashboard },
    { path: "/terms", component: Terms },
    { path: "/about", component: Aboutus },
  ],
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
});


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')