<template>
    <div>
        <Navbar></Navbar>
        <div>
            <p class="terms-title">Terms of Use</p>
            <p class="terms">Welcome to NUBA! By accessing our website or using our
                application,
                you agree to be bound by these Terms of Use and all applicable laws and regulations. If you do not agree
                with any of these terms, you are prohibited from using or accessing this site. 1. Use License Permission
                is
                granted to temporarily download one copy of the materials (information or software) on NUBA's website for personal, non-commercial transitory viewing only. This is the
                grant
                of a license, not a transfer of title, and under this license, you may not: modify or copy the
                materials;
                use the materials for any commercial purpose, or for any public display (commercial or non-commercial);
                attempt to decompile or reverse engineer any software contained on NUBA's
                website; remove any copyright or other proprietary notations from the materials; transfer the materials
                to
                another person or "mirror" the materials on any other server. This license shall automatically terminate
                if
                you violate any of these restrictions and may be terminated by NUBA at any
                time.
                Upon terminating your viewing of these materials or upon the termination of this license, you must
                destroy
                any downloaded materials in your possession whether in electronic or printed format. 2. Disclaimer The
                materials on NUBA's website are provided on an 'as is' basis. NUBA makes no warranties, expressed or implied, and hereby disclaims and negates
                all
                other warranties including, without limitation, implied warranties or conditions of merchantability,
                fitness
                for a particular purpose, or non-infringement of intellectual property or other violation of rights.
                Further, NUBA does not warrant or make any representations concerning the
                accuracy, likely results, or reliability of the use of the materials on its website or otherwise
                relating to
                such materials or on any sites linked to this site. 3. Limitations In no event shall NUBA or its suppliers be liable for any damages (including, without limitation,
                damages
                for loss of data or profit, or due to business interruption) arising out of the use or inability to use
                the
                materials on NUBA's website, even if NUBA or a
                NUBA authorized representative has been notified orally or in writing of the
                possibility of such damage. 4. Accuracy of Materials The materials appearing on NUBA's website could include technical, typographical, or photographic errors.
                NUBA does not warrant that any of the materials on its website are accurate, complete, or current.
                NUBA may make changes to the materials contained on its website at any time without
                notice. However, NUBA does not make any commitment to update the materials.
                5.
                Links NUBA has not reviewed all of the sites linked to its website and is not
                responsible for the contents of any such linked site. The inclusion of any link does not imply
                endorsement
                by NUBA of the site. Use of any such linked website is at the user's own
                risk. 6.
                Modifications NUBA may revise these terms of use for its website at any time
                without notice. By using this website you are agreeing to be bound by the then current version of these
                terms of use. 7. Governing Law These terms and conditions are governed by and construed in accordance
                with
                the laws of [Your State/Country] and you irrevocably submit to the exclusive jurisdiction of the courts
                in
                that State or location. Thank you for visiting NUBA!</p>
        </div>
        <ContactUs></ContactUs>


    </div>
</template>


<script>
import ContactUs from '@/components/dashboard/ContactUs.vue';
import Navbar from '@/components/header/Navbar.vue';


export default {

    mounted() {
        console.log("Current route path:", this.$route.path);
        console.log("Current route name:", this.$route.name);
        console.log("Current route params:", this.$route.params);
        console.log("Current route query:", this.$route.query);
    },
    components: {
        ContactUs,
        Navbar
    }
}
</script>

<style>
@import '/src/assets/css/common.css';
.terms {
    font-family: 'Epilogue Regular';
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #fff;
}

.terms-title {
    font-family: 'Acherus Bold';
    font-size: 132px;
    font-weight: 700;
    line-height: 132px;
    text-align: center;
    color: #fff;
    margin-top: 100px;

}
/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px){
    .terms-title {
    font-size: 60px;
    line-height: normal;
    margin-top: 27px;
}  
}
@media (max-width: 575.98px){
    .terms-title {
    font-size: 40px;
    line-height: normal;
    margin-top: 27px;
}
}
</style>