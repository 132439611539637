<template>
    <div class="platforms">
        <div class="top">
            <div style="width: calc(100vw/2) !important;" data-aos="fade-up" data-aos-duration="1000">
                <div style="display: flex; margin-top: 60px; margin-left: 80px;" class="app-icon">
                    <img class="icon" src="@/assets/icons/apple.png" alt="">
                    <img class="icon" src="@/assets/icons/android.png" alt="">
                    <img class="icon" src="@/assets/icons/window.png" alt="">
                </div>
                <p class="works-title">What they say about us</p>
                <p class="works-subtitle">You can use your existing mobile, tablet, and
                    <br>desktop
                </p>
            </div>

            <div class="tab tab-rigth" data-aos="fade-down" data-aos-duration="1000">
                <img src="@/assets/images/tab_crop.png" alt="">
            </div>
        </div>


        <div class="bottom">
            <div class="tab tab-rigth" data-aos="fade-right" data-aos-duration="1000">
                <img src="@/assets/images/laptop_crop.png" alt="">
            </div>
            <div class=" margin-top fixed-img-height" data-aos="fade-up" data-aos-duration="1000">
                <img src="@/assets/images/mobile_crop.png" alt="">
            </div>
        </div>
    </div>
</template>
<script>
import AOS from 'aos';
import 'aos/dist/aos.css';
export default {
    mounted() {
        AOS.init({
            duration: 800, // Animation duration
            offset: 200, // Offset (in px) from the original trigger point
            easing: 'ease', // Easing type
            once: false // Whether animation should only happen once
        });
    },
}
</script>
<style>
@import '/src/assets/css/common.css';
.fixed-img-height{
    width: 385px;
    height: 330px;
}
.fixed-img-height img{
    height: 100%;
    width: 100%;
}
.tab-rigth{
    height: 300px;
    width: 430px !important;
}
.tab-rigth img{
    height: 100% !important;
    width: 100% !important;
}
/* .fixed-img-height img{
    animation: Up-Down  2s ease-in-out infinite alternate-reverse both;
}
@keyframes Up-Down  {
  0% {
    transform: translatey(10px);
  }

  100% {
    transform: translatey(-8px);
  }
}
.tab-rigth img{
    animation: left-right 2s ease-in-out infinite alternate-reverse both;
}
@keyframes left-right {
  0% {
    transform: translatex(10px);
  }

  100% {
    transform: translatex(-8px);
  }
} */
.platforms {
    border-radius: 24px;
    background-color: rgba(255, 255, 255, 0.05);
    margin-top: 100px;
}



.top {
    display: flex;
    justify-content: space-between;
}

.tab {
    width: calc(100vw / 2);
}

.tab img {
    width: 100%;
}

.bottom {
    display: flex;
    justify-content: space-between;
}

.icon {
    height: 28px;
    width: 28px;
    margin-right: 25px;
}

.works-title {
    margin-left: 80px;
    margin-top: 26px;
    font-size: 48px;
    font-weight: 400;
    line-height: 62.4px;
    text-align: left;
    font-family: 'Epilogue Regular';
    color: #FFFFFF;
}

.works-subtitle {
    margin-left: 80px;
    font-family: 'Epilogue Regular';
    font-size: 24px;
    font-weight: 400;
    line-height: 33.6px;
    text-align: left;
    color: rgba(255, 255, 255, 0.5);

}
/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px){
    .works-title {
        margin-left: 20px;
        font-size: 32px;
        line-height: 1.1;
    }
    .app-icon {
        margin-left: 20px !important;
    }

    .works-subtitle {
        margin-left: 20px;
        font-size: 19px;
        line-height: 1.3;
    }

    .platforms {
        margin-top: 40px
    } 
}
/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px){
    .bottom {
    gap: 1rem;
}
.fixed-img-height {
    width: 304px;
        height: 260px;
}
    .tab-rigth{
        height: 220px;
    width: 270px !important;
    }
    .works-title {
        margin-left: 20px;
        font-size: 32px;
        line-height: 1.1;
    }
    .app-icon {
        margin-left: 20px !important;
    }

    .works-subtitle {
        margin-left: 20px;
        font-size: 19px;
        line-height: 1.3;
    }

    .platforms {
        margin-top: 40px
    }
}
/* for mobile secreen */
@media (max-width: 575.98px) {
    .bottom {
    gap: 1rem;
}
.fixed-img-height {
    width: 253px;
        height: 181px;
}
    .tab-rigth{
        height: 140px;
    width: 270px !important;
    }
    .works-title {
        margin-left: 20px;
        font-size: 37px;
        line-height: 1.1;
    }

    .app-icon {
        margin-left: 20px !important;
    }

    .works-subtitle {
        margin-left: 20px;
        font-size: 19px;
        line-height: 1.3;
    }

    .platforms {
        margin-top: 40px
    }
}
</style>