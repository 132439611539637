<template>
  <div class="video-container">
    <div class="video-card">
      <iframe class="player" :src="embedUrl" frameborder="0" allowfullscreen></iframe>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    youtubeUrl: {
      type: String,
      required: true
    }
  },
  computed: {
    embedUrl() {
      const videoId = this.getVideoId(this.youtubeUrl);
      return `https://www.youtube.com/embed/${videoId}`;
    }
  },
  methods: {
    getVideoId(url) {
      const regExp = /^.*((youtu\.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v=?(.*)/;
      const match = url.match(regExp);
      return (match && match[7].length === 11) ? match[7] : '';
    }
  }
};
</script>

<style>

.video-container {
  position: relative;
    padding-top: 0.25%;
    margin-top: 110px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 50vh;
}

.player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
::v-deep.ytp-chrome-top.ytp-show-cards-title {
    display: none !important;
}
@media (max-width: 575.98px){
  .video-container{
    margin-top: 40px;
  }
}
</style>
