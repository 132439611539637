<template>
    <div class="my-navbar nav">
        <input type="checkbox" id="nav-check">
        <div class="nav-header">
            <div class="nav-title">
                <img class="my-logo" src="/icon.svg">
            </div>
        </div>
        <div class="nav-btn">
            <label for="nav-check">
                <span></span>
                <span></span>
                <span></span>
            </label>
        </div>

        <div class="my-actions nav-list nav-btn">
            <label for="nav-check">
                <router-link to="/" class="text-decoration-none">
                    <p :class="currentComponent == 'trial' ? 'selected-action' : ''" @click="scrollToID('trial')">Home
                    </p>
                </router-link>

                <router-link to="/" class="text-decoration-none">
                    <p :class="currentComponent == 'partner' ? 'selected-action' : ''" @click="scrollToID('partner')">
                        Our
                        solutions</p>
                </router-link>

                <router-link to="/" class="text-decoration-none">
                    <p :class="currentComponent == 'price' ? 'selected-action' : ''" @click="scrollToID('price')">Price
                    </p>
                </router-link>

                <router-link to="/about" class="text-decoration-none">
                    <p class="text-decoration-none">About Nuba</p>
                </router-link>


            </label>
        </div>

        <div class="my-yellow-btn my-logo" @click="showModal">
            <p>Contact us</p>
        </div>
        <b-modal body-class="p-0" v-model="isModalVisible" size="xl" centered hide-footer hide-header
            content-class="modal-style">
            <RequestModal />
        </b-modal>
    </div>


</template>

<script>
import RequestModal from '../modals/RequestModal.vue';



export default
    {
        props: {
            currentComponent: {
                type: String,
                require: true
            }
        },
        data() {
            return {
                isModalVisible: false,
            }
        },

        components: {
            RequestModal
        },

        methods: {

            showModal() {
                this.isModalVisible = true;
            },
            closeModal() {
                this.isModalVisible = false;
            },

            scrollToID(id) {
                document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
            },
        },

    }

</script>


<style>
@import '/src/assets/css/header/navbar.css';

.my-yellow-btn {
    font-family: 'Epilogue Regular';
}

.my-actions label {
    display: flex;
}

.text-decoration-none {
    text-decoration: none;
}

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
    .my-actions label {
        display: flex;
    }

    .nav>.nav-list {
        gap: 0rem;
    }

    .my-actions p {
        margin-left: 10px;
        margin-right: 14px;
        margin-bottom: 0;
    }

    .my-navbar {
        width: 80% !important;
    }
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
    .my-actions label {
        display: flex;
    }

    .nav>.nav-list {
        gap: 0rem;
    }

    .my-actions p {
        margin-left: 3px;
        margin-right: 14px;
        margin-bottom: 0;
    }

    .my-navbar {
        width: 100% !important;
    }
}
</style>