<template>
  <transition name="page" mode="out-in">
    <router-view></router-view>
  </transition>
</template>

<script>


export default {
  name: 'App',

}
</script>


<style>
body {
  background-color: #151413 !important;
  /* width: 90%; */
  max-width: 1280px;
  margin: 0 auto !important;
  justify-content: center;
  align-items: center;
}

/* html {
  background-color: #151413;
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 200px;
} */
html{
  overflow-x: hidden;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 12px;


}



@media (max-width: 768px) {
  .navbar {
    margin-left: 30px;
    margin-right: 30px;
  }

body{
  padding: 10px 22px;
}
}
</style>
