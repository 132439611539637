<template>
   
    <div class="request-modal" v-if="showModal">
        <div v-if="showModal" class="modal-overlay" @click="closeModal"></div>
        <i class="fas fa-times" @click="closeModal"></i>
        <div style="width: calc(100vw / 4);" class="width-100">
            <p class="msg">Get dating clients and manage restaurant with one system</p>
            <p class="msg-2">Discover how we can help you get more clients and manage your restaurant with one system
            </p>
        </div>
        <div style="width: calc(100vw / 5);margin-left: 120px;" class="width-100">
            <img width="100%" src="@/assets/images/polygons.png" alt="">
            <div class="input-card">
                <b-form-input class="input-style" v-model="name" placeholder="Full name"></b-form-input>
                <b-form-input class="input-style" v-model="comopanyName" placeholder="Company name"></b-form-input>
                <b-form-input class="input-style" v-model="phoneNumber" placeholder="Phone number"></b-form-input>
                <b-form-input class="input-style" v-model="email" placeholder="Email"></b-form-input>

                <b-form-radio-group class="radio-style" v-model="selected" :options="options" value-field="item"
                    text-field="name" disabled-field="notEnabled"></b-form-radio-group>



                <div @click="submitForm" class="yellow-button" style="margin-bottom: 30px;">
                    Submit form
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/axios';

export default {
    data() {
        return {
            name: null,
            comopanyName: null,
            phoneNumber: null,
            email: null,
            selected: 'PARTNER',
            options: [
                { item: 'PARTNER', name: 'Partner' },
                { item: 'RES_OWNER', name: 'Restaurant Owner' },

            ],
            showModal: true // Add the showModal property to control modal visibility

         
        }
    },
    methods: {
       async submitForm() {
            let url = `restaurant/restaurant-requests/`
            let payoad = {
                "name": this.name,
                "company_name": this.comopanyName,
                "phone_number": this.phoneNumber,
                "email": this.email,
                "restaurant_types": this.selected,
                "is_approved": false
            };
            

            let result = await axios.reqApi(url).post(payoad);
            console.log(result)
            // axios.post(url, payoad)
            //     .then(function (response) {
            //         console.log(response);
            //         if (response.status == 201) {
            //             console.log('Done');
            //         }
            //     })
            //     .catch(function (error) {
            //         console.log(error);
            //     });

        },
        closeModal() {
            this.showModal = false; // Set showModal to false to hide the modal
        }
    }

} 
</script>

<style>
@import '/src/assets/css/common.css'; 
.radio-style {
    display: flex;
    justify-content: space-between;
    color: #000;
}

.input-style {
    border-width: 0 0 2px 0;
    border-color: rgba(21, 20, 19, 0.5);
    font-family: 'Epilogue Regular';
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
    color: rgba(21, 20, 19, 0.5);
    margin-bottom: 32px;
}
.input-style:focus{
    box-shadow: none;
}
.yellow-button{
    font-family: 'Epilogue Regular';
}
.input-card {
    width: 100%;
    height: 100%;
    /* margin-left: 120px; */
    background-color: #fff;
    padding: 16px 32px 16px 32px;

}
.request-modal i{
    position: absolute;
    top: 18px;
    right: 30px;
    font-size: 1.5rem;
    cursor: pointer;
}
.request-modal {
    background-color: #B68D40;
    color: white;
    border-radius: 24px;
    display: flex;
    padding: 100px 80px 0 80px !important;
    align-items: center;

}


.msg {
    font-family: 'Epilogue Regular';
    font-size: 44px;
    font-weight: 500;
    line-height: 52.8px;
    text-align: left;

}

.msg-2 {
    font-family: 'Epilogue Regular';
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    margin-bottom: 50px;

}
label.custom-control-label{
    margin-left: 5px !important;
}
/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px){
    .request-modal{
        flex-wrap: wrap;
        padding: 40px 20px 0 20px !important;
    }
    .width-100{
        width: 100% !important;
        margin-left: 0 !important;
    }
    label.custom-control-label {
    margin-left: 4px !important;
}
.msg {
    font-size: 35px;
    line-height: 1.1;
}
}
/* For mobile responsiveness */
@media (max-width: 575.98px) {
    .request-modal{
        flex-wrap: wrap;
        padding: 40px 20px 0 20px !important;
    }
    .width-100{
        width: 100% !important;
        margin-left: 0 !important;
    }
    label.custom-control-label {
    margin-left: 4px !important;
}
}
</style>
