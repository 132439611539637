<template>
    <div>
        <div class="row mt-5">
            <!-- <div class="col-md-12">
               
            </div> -->
            <div class="col-md-5 column-flex">
                <div class="message" >
                <p data-aos="fade-up" data-aos-duration="1000">
                    <span class="me-3"> Boost </span> Efficiency
                    
                
                </p>
                
                <!-- <p data-aos="fade-up" data-aos-duration="1020">
                    <span>More</span>
                    Solutions
                </p>
                <p data-aos="fade-up" data-aos-duration="1040">
                    <span>More</span>
                    Revenue
                </p> -->
            </div>
                <div class="trial">
                <p>Streamline Reservations and Enhance Customer Experience with Our Innovative
                    POS Solution</p>
                </div>
                <div class="yellow-button" @click="showModal">
                    <p>Start Your Free Trial</p>
                </div>
            </div>
            <div class="col-md-7">
                <div class="nuba-resturant" data-aos="fade-up"  data-aos-duration="1000">
                <img src="@/assets/images/restaurant.png" alt="" class="img-fluid" srcset="">
            </div>
            </div>
        </div>


<!-- 
        <div class="online-container " >
           
           
           
            <div class=" col-md-4 max-width">
              
            </div>
    
        </div> -->
        <b-modal body-class="p-0" v-model="isModalVisible" size="xl" centered hide-footer hide-header
            content-class="modal-style">
            <RequestModal />
        </b-modal>

    </div>
</template>
<script>
import RequestModal from '../modals/RequestModal.vue';
import AOS from 'aos';
import 'aos/dist/aos.css';
export default
    {
        data() {
            return {
                isModalVisible: false,
            }
        },

        components: {
            RequestModal
        },

        methods: {

            showModal() {
                this.isModalVisible = true;
            },
            closeModal() {
                this.isModalVisible = false;
            },
        },
        mounted() {
    AOS.init({
        duration: 800, // Animation duration
        offset: 200, // Offset (in px) from the original trigger point
        easing: 'ease', // Easing type
        once: false // Whether animation should only happen once
    });
},
    }

</script>
<style>
@import '/src/assets/css/common.css'; 
/* @import '/src/assets/css/header/navbar.css'; */
.column-flex{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

</style>