<template>
    <div class="">
        <div class="footer-container">
        <div class="contact-container  ">
            <p data-aos="zoom-in">Let’s discuss <br> how we can <br> help you</p>

            <div class="yellow-button-end" @click="showModal">
                Contact us
            </div>

        </div>

        <div v-if="this.$route.path != '/terms'" class="terms-footer">
            <p>Copyright © 2024 | All rights reserved
                <router-link to="/terms">
                    <span>Terms of Use</span>
                </router-link>
            </p>

        </div>
    </div>
        <b-modal body-class="p-0" v-model="isModalVisible" size="xl" centered hide-footer hide-header
            content-class="modal-style">
            <RequestModal />
        </b-modal>
    </div>
</template>
<script>
import RequestModal from '../modals/RequestModal.vue';
import AOS from 'aos';
import 'aos/dist/aos.css';
export default {
    data() {
        return {
            isModalVisible: false,
        };
    },
    components:
    {
        RequestModal
    },
    methods: {
        showModal() {
            this.isModalVisible = true;
        },
        closeModal() {
            this.isModalVisible = false;
        },
    },
    mounted() {
        AOS.init({
            duration: 1000, // Animation duration
            offset: 200, // Offset (in px) from the original trigger point
            easing: 'ease', // Easing type
            once: false, // Whether animation should only happen once
        });
    },

}
</script>
<style>
@import '/src/assets/css/common.css'; 
.footer-container{
    width: 90%;
  margin: 0 auto !important;
}
a {
    color: #fff !important;
}

.modal-style {
    background-color: transparent;

}

.terms-footer {
    margin-top: 80px;
}

.terms-footer p {
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 15.4px;
    text-align: left;
    color: rgba(255, 255, 255, 0.5);

}

.terms-footer span {
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 15.4px;
    text-align: left;

    color: #fff;
    margin-left: 20px;

}

.contact-container {
    font-family: 'Acherus Bold';
    font-size: 80px;
    font-weight: 700;
    line-height: 1.2;
    text-align: left;
    color: #fff;
    margin-top: 200px;
    display: flex;
    justify-content: space-between;
    align-items: end;
}

.yellow-button-end {
    font-family: 'Epilogue Regular';
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;

    display: flex;
    background-color: #B68D40;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-top: 36px;
    padding: 20px 28px 20px 28px;
    height: 50px;

    cursor: pointer;

  
}
@media (max-width: 1440px) { 
    #contact_us {
        width: 100% !important;
        max-width: 100% !important;
        min-width: 100% !important;
    }
}
/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 
    .contact-container {
    font-size: 60px;
    line-height: normal;
    margin-top: 50px;
}
}
  /* For mobile responsiveness */
  @media (max-width: 575.98px){
        .contact-container{
            flex-wrap: wrap;
            font-size: 45px;
            line-height: 1.1;
            margin-top: 50px;
        }
        .yellow-button-end {
            margin-top: 5px;
    }
}
</style>