<template>
  <div class="bf">
    <Navbar :currentComponent="currentComponent"></Navbar>
    <StartTrial id="trial"></StartTrial>
    <Partner id="partner"></Partner>
    <VideoPlayer id="player" :youtubeUrl="'https://www.youtube.com/watch?v=WdWEMXnHBVI'"></VideoPlayer>
    <Platform id="platform"></Platform>
    <Benifits id="benifits"></Benifits>
    <Price id="price"></Price>
    <ContactUs id="contact_us"></ContactUs>
  </div>
</template>
 
<script>
import Navbar from '../header/Navbar.vue';
import Benifits from './Benifits.vue';
import ContactUs from './ContactUs.vue';
import Partner from './Partner.vue';
import Platform from './Platform.vue';
import Price from './Price.vue';
import StartTrial from './StartTrial.vue';
import VideoPlayer from './VideoPlayer.vue';

export default {
  name: 'App',
  data() {
    return {
      currentComponent: 'trial'
    }
  },
  components: {
    Navbar,
    StartTrial,
    Partner,
    VideoPlayer,
    Platform,
    Benifits,
    Price,
    ContactUs
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    isVisible(el) {
      if (el) {
        const rect = el.getBoundingClientRect();
        const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
        return (
          rect.top >= 0 &&
          rect.bottom <= windowHeight
        );
      }
      return false;
    },
    handleScroll() {
      let el = document.getElementById('trial');
      if (this.isVisible(el)) {
        this.currentComponent = 'trial';
        return;
      }
      el = document.getElementById('benifits');
      if (this.isVisible(el)) {
        this.currentComponent = 'benifits';
        return;
      }
      el = document.getElementById('price');
      if (this.isVisible(el)) {
        this.currentComponent = 'price';
        return;
      }
      el = document.getElementById('contact_us');
      if (this.isVisible(el)) {
        this.currentComponent = 'contact_us';
        return;
      }
    }
  }
}
</script>

<style>
#contact_us{
  background: #000000;
    min-width: 1583px;
    position: absolute;
    left: 0;
    right: 0;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    max-width: 100%;
}
/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) { 
  #contact_us{
    width: 100%;
        max-width: 100%;
        min-width: 100%;
  }
 }
/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {  
   #contact_us{
    width: 100%;
        max-width: 100%;
        min-width: 100%;
  }
 }

@media (max-width: 575.98px) {
  #contact_us{
    width: 100%;
        max-width: 100%;
        min-width: 100%;
  }
}
</style>
