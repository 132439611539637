<template>
    <div class="bcard-shape" data-aos="fade-down" ref="cardShape" @mouseenter="animateTitle(true)" @mouseleave="animateTitle(false)">
        <div class="icons">
            <img :src="require(`@/assets/icons/${imgPath}`)" alt="">
        </div>

        <div class="content-wrapper">
            <p class="bcard-title" :class="{ 'moved-up': titleMovedUp }">{{ title }}</p>
            <p class="bcard-subtitle" :class="{ 'show': subtitleVisible }">{{ subtitle }}</p>
        </div>
    </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';
export default {
    props: {
        imgPath: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        subtitle: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            subtitleVisible: false,
            titleMovedUp: false
        };
    },
    mounted() {
        this.setCardHeight();
        window.addEventListener('resize', this.setCardHeight);
        AOS.init({
            duration: 800, // Animation duration
            offset: 200, // Offset (in px) from the original trigger point
            easing: 'ease', // Easing type
            once: false // Whether animation should only happen once
        });
    },
    unmounted() {
        window.removeEventListener('resize', this.setCardHeight);
    },
    methods: {
        setSubtitle(show) {
            this.subtitleVisible = show;
        },
        animateTitle(shouldMoveUp) {
            this.titleMovedUp = shouldMoveUp;
            this.setSubtitle(shouldMoveUp);
        },
        setCardHeight() {
            console.log('this.$refs.cardShape', this.$refs.cardShape)
            const cardWidth = this.$refs.cardShape.offsetWidth;
            this.$refs.cardShape.style.height = `${cardWidth * .9}px`;
        },
        
    }
}
</script>

<style>
@import '/src/assets/css/common.css';

.bcard-title {
    transition: 0.4s ease-in-out;
    font-family: 'Epilogue Regular';
}

.moved-up {
    transform: translateY(-40%);
    transition: 0.4s ease-in-out;
}

.bcard-subtitle {
    display: none;
    font-family: 'Epilogue Regular';
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    transition: 0.4s ease-in-out;
    text-align: left;
    color: rgba(255, 255, 255, 0.5);
}

.bcard-subtitle.show {
    display: block;
    transition: 0.4s ease-in-out;
    /* transition: opacity 0.5s ease; */
}

.bcard-shape {
    background-color: rgba(255, 255, 255, 0.05);
    padding: 20px;
    gap: 0px;
    border-radius: 24px;
    display: flex;
    transition: 0.4s ease-in-out;
    flex-direction: column;
    justify-content: space-between;
    width: 355px;
}

.bcard-shape:hover {
    background-color: #2c2b2a;
}

.bcard-title {
    font-family: 'Epilogue Regular';
    font-size: 24px;
    font-weight: 400;
    line-height: 44px;
    text-align: left;
    transition: 0.4s ease-in-out;
    color: #fff;
    margin: 0;
}

.icons {
    height: 80px;
    width: 80px;
}

.icons img {
    height: 100%;
    width: 100%;
}
/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px){
    .bcard-shape{
        height: 100% !important;
    }
    .bcard-title {
    font-size: 20px;
}
.moved-up {
        margin: 0;
        line-height: normal;
        margin-top: 10px;
        margin-bottom: 2px;
}
.icons {
    height: 90px;
    width: 90px;
}
}
/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px){
    .bcard-shape{
        height: 100% !important;
    }
    .bcard-title {
    font-size: 20px;
}
.moved-up {
        margin: 0;
        line-height: normal;
        margin-top: 10px;
        margin-bottom: 2px;
}
}

/* for mobile secreen */
@media (max-width: 575.98px) {
    .bcard-shape {
        height: 100% !important;
    }

    .bcard-title {
        font-size: 20px;
        margin: 0;
    }

    .moved-up {
        transform: translateY(-30%);
        line-height: 1.2;
        margin-top: 23px;
    }
}
</style>
