import axios from 'axios'
// import store from '@/store'

const baseUrl = process.env.VUE_APP_API_ENDPOINT

const axiosInstance = axios.create({
  headers:{
    'Content-Type': 'application/json'
  }
})

// axiosInstance.interceptors.request.use(
//   config => {
//     let token = store.getters['USER/token']
//     if(token) config.headers["Authorization"] = 'Bearer ' + token
//     return config
//   },
//   error => Promise.reject(error)
// )

// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response
//   },
//   (error) => {
//     let token = store.getters['USER/token']
//     if(error.response.status == 401 && token != null){
//       store.dispatch('CLEAR')
//       window.location(`${window.location.origin}/login`)
//       return
//     }
//     return Promise.reject(error)
//   }
// )

export default {
  reqApi: url => ({
    post: data => axiosInstance.post(baseUrl + url, data),
    postWithCustomHeader: (data, options) => axiosInstance.post(baseUrl + url, data, options),
    get: (config) => axiosInstance.get(baseUrl + url, config),
    getWithBody: (data) => axiosInstance.get(baseUrl + url, {}, data),
    getBuffer: (config) => axiosInstance.get(baseUrl + url, {responseType: 'blob', ...config}),
    put: data => axiosInstance.put(baseUrl + url, data),
    patch: data => axiosInstance.patch(baseUrl + url, data),
    delete: data => axiosInstance.delete(baseUrl + url, { data }),
  }),
  downloadFile: (res) => {
    let filename = "";
    let disposition = res.headers['content-disposition'];
    let contentType = res.headers['content-type']
    if (disposition && disposition.indexOf('attachment') !== -1) {
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(disposition);
      console.log(matches);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    console.log(filename);
    const url = window.URL.createObjectURL(new Blob([res.data],{type: contentType}))
    const link = document.createElement('a')
    link.href = url;
    link.setAttribute('download', filename)
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
  }
}