<template>
    <div class="b-price-container">
        <div class="b-heading">
            <p class="b-heading-title" data-aos="fade-down">At super affordable price</p>
            <ul class="nav nav-pills mb-3 switch-btn" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="pills-Monthly-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-Monthly" type="button" role="tab" aria-controls="pills-Monthly"
                        aria-selected="true">Monthly</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="pills-year-tab" data-bs-toggle="pill" data-bs-target="#pills-year"
                        type="button" role="tab" aria-controls="pills-year" aria-selected="false">Yearly <span
                            class="b-discount">
                            -10%</span></button>
                </li>


            </ul>
        </div>
        <div class="price-main-card pb-5 mb-5">
            <!-- Free plan card -->
            <div class="card-content" data-aos="fade-up">
                <div class="plan-type">
                    <p>{{ planType }}</p>
                </div>
                <div class="price">
                    <p>{{ price }}
                        <sup class="validity">{{ validity }}</sup>
                    </p>
                </div>
                <div class="start-btn">
                    <div class="yellow-button w-100">
                        {{ buttonText }}
                    </div>
                    <p class="p-fade">{{ fadeText }}</p>
                </div>
                <div class="cards-options">
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button reservation-btn" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <div class="checkboxes__row">
                                        <div class="row">
                                            <div class="col-sm-8 width-60">
                                                <div class="sub-menu">
                                            <img src="@/assets/icons/yelow-checked.png" alt="">
                                        <p>Booking</p>
                                        </div>
                                            </div>
                                            <div class="col-sm-4 width-40">
                                                <span class="status-btn">Free</span>
                                            </div>
                                        </div>
                                       
                                       
                                       
                                    </div>
                                </button>
                            </h2>
                            <div id="collapseOn" class="accordion-collapse show" aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <ul class="packages-">
                                        <li><span> <i class="fa-solid fa-check"></i></span> Reservations 24/7 (Nuba app)
                                        </li>
                                        <li class="disabled-menu"><span> <i
                                                    class="fa-solid fa-check"></i></span>Reservations (NubaBook)</li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Availability plan</li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Automatic confirmation</li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Manual waiting waitlist</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- table-accordian -->
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button reservation-btn" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true"
                                    aria-controls="collapseOnes">
                                    <div class="checkboxes__row">
                                        <div class="row">
                                            <div class="col-sm-8 width-60">
                                                <div class="sub-menu">
                                            <img src="@/assets/icons/disable-check.png" alt="">
                                        <p class="disabled-menu">Table</p>
                                        </div>
                                            </div>
                                            <div class="col-sm-4 width-40">
                                                <span class="status-btn disable-free">+ $17</span>
                                            </div>
                                        </div>
                                    </div>
                                   
                                </button>
                            </h2>
                            <div id="collapseO" class="accordion-collapse show" aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body disable-menu-list">
                                    <ul class="packages-">
                                        <li><span> <i class="fa-solid fa-check"></i></span>Floors /Table </li>
                                        <li class="disabled-menu"><span> <i class="fa-solid fa-check"></i></span>Table
                                            plan </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Real-time spending alerts
                                        </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Real-time customer feedback
                                            and requests</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Analytics accordian -->
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button reservation-btn" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true"
                                    aria-controls="collapseOne">
                                    <div class="checkboxes__row">
                                        <div class="row">
                                            <div class="col-sm-8 width-60">
                                                <div class="sub-menu">
                                            <img src="@/assets/icons/disable-check.png" alt="">
                                        <p class="disabled-menu">Analytics</p>
                                        </div>
                                            </div>
                                            <div class="col-sm-4 width-40">
                                                <span class="status-btn disable-free">+ $12</span>
                                            </div>
                                        </div>
                                    </div>
                                   

                                </button>
                            </h2>
                            <div id="collapsene" class="accordion-collapse show"
                                aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body disable-menu-list">
                                    <ul class="packages-">
                                        <li><span> <i class="fa-solid fa-check"></i></span>Pos </li>
                                        <li class="disabled-menu"><span> <i class="fa-solid fa-check"></i></span>Table
                                            plan </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Payment </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Delivery </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Marketing </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Booking </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Robot </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Marketing -->
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button reservation-btn" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true"
                                    aria-controls="collapseOne">
                                    <div class="checkboxes__row">
                                        <div class="row">
                                            <div class="col-sm-8 width-60">
                                                <div class="sub-menu">
                                            <img src="@/assets/icons/disable-check.png" alt="">
                                        <p class="disabled-menu">Marketing</p>
                                        </div>
                                            </div>
                                            <div class="col-sm-4 width-40">
                                                <span class="status-btn disable-free">+ $15</span>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="colapseOne" class="accordion-collapse show "
                                aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body disable-menu-list">
                                    <ul class="packages-">
                                        <li><span> <i class="fa-solid fa-check"></i></span> Discount strategies </li>
                                        <li class="disabled-menu"><span> <i class="fa-solid fa-check"></i></span>Table
                                            plan </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span> Dating mode </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Story </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Event </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Gift </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Guests option  -->
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button reservation-btn" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true"
                                    aria-controls="collapseOne">
                                    <div class="checkboxes__row">
                                        <div class="row">
                                            <div class="col-sm-8 width-60">
                                                <div class="sub-menu">
                                            <img src="@/assets/icons/disable-check.png" alt="">
                                        <p class="disabled-menu">Guests</p>
                                        </div>
                                            </div>
                                            <div class="col-sm-4 width-40">
                                                <span class="status-btn disable-free">+ $23</span>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="collpseOne" class="accordion-collapse sow" aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body disable-menu-list">
                                    <ul class="packages-">
                                        <li><span> <i class="fa-solid fa-check"></i></span> guests data and profiles
                                        </li>
                                        <li class="disabled-menu"><span> <i class="fa-solid fa-check"></i></span>Chat
                                        </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span> Vip list </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Black list </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Staff -->
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button reservation-btn" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <div class="checkboxes__row">
                                        <div class="row">
                                            <div class="col-sm-8 width-60">
                                                <div class="sub-menu">
                                            <img src="@/assets/icons/disable-check.png" alt="">
                                        <p class="disabled-menu">Staff</p>
                                        </div>
                                            </div>
                                            <div class="col-sm-4 width-40">
                                                <span class="status-btn disable-free">+ $23</span>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="ollapseOne" class="accordion-collapse show" aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body disable-menu-list">
                                    <ul class="packages-">
                                        <li><span> <i class="fa-solid fa-check"></i></span>Add access </li>
                                        <li class="disabled-menu"><span> <i class="fa-solid fa-check"></i></span>
                                            Results and evaluation </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Menu -->
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button reservation-btn" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <div class="checkboxes__row">
                                        <div class="row">
                                            <div class="col-sm-8 width-60">
                                                <div class="sub-menu">
                                            <img src="@/assets/icons/disable-check.png" alt="">
                                        <p class="disabled-menu">Menu</p>
                                        </div>
                                            </div>
                                            <div class="col-sm-4 width-40">
                                                <span class="status-btn disable-free">+ $10</span>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="cpseOne" class="accordion-collapse show " aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body disable-menu-list">
                                    <ul class="packages-">
                                        <li><span> <i class="fa-solid fa-check"></i></span>Personalise</li>
                                        <li class="disabled-menu"><span> <i class="fa-solid fa-check"></i></span>
                                            Promotions </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Stocks</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Integrations -->
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button reservation-btn" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true"
                                    aria-controls="collapseOne">
                                  
                                    <div class="checkboxes__row">
                                        <div class="row">
                                            <div class="col-sm-8 width-60">
                                                <div class="sub-menu">
                                            <img src="@/assets/icons/disable-check.png" alt="">
                                            <p class="disabled-menu">Integrations</p>
                                        </div>
                                            </div>
                                            <div class="col-sm-4 width-40">
                                                <span class="status-btn">Free</span>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="clapseOne" class="accordion-collapse show"
                                aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body ">
                                    <ul class="packages-">
                                        <li><span> <i class="fa-solid fa-check"></i></span>Pos </li>
                                        <li class="disabled-menu"><span> <i class="fa-solid fa-check"></i></span>Payment
                                        </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Delivery </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Marketing </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Booking </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Robot </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Additional -->
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button reservation-btn" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true"
                                    aria-controls="collapseOne">
                                   

                                    <div class="checkboxes__row">
                                        <div class="row">
                                            <div class="col-sm-8 width-60">
                                                <div class="sub-menu">
                                                    <img src="@/assets/icons/additional-icon.png" alt="">
                                            <p class="disabled-menu">Additional</p>
                                        </div>
                                            </div>
                                            <div class="col-sm-4 width-40">
                                                <span class="status-btn disable-free">+ $32</span>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="collaseOe" class="accordion-collapse show"
                                aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body disable-menu-list">
                                    <ul class="packages- additiona-li">
                                        <li> - Sms notification <span class="status-btn ">+ $32</span>  <img src="@/assets/icons/white-checked.png" alt=""></li>
                                        <li class="disabled-menu"> - Online wait list <span class="status-btn ">+ $13</span>  <img src="@/assets/icons/disable-check.png" alt=""></li>
                                        <li> - Online payment <span class="status-btn ">+ $32</span>  <img src="@/assets/icons/white-checked.png" alt=""></li>
                                        <li> - Multiple locations <span class="status-btn ">+ $32</span>  <img src="@/assets/icons/white-checked.png" alt=""></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Basic  card -->
            <div class="card-content dark-card" data-aos="fade-up">
                <div class="plan-type">
                    <p>Basic </p>
                    <div class="min">
                        <p>{{ minDuration }}</p>
                    </div>
                </div>

                <div class="price">
                    <p> $39
                        <sup class="validity">{{ validity }}</sup>
                    </p>
                </div>
                <div class="yellow-button w-100 subscribe-btn">
                    Subscribe now
                </div>
                <p class="p-fade">{{ fadeText }}</p>
                <div class="cards-options">
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingBasic">
                                <button class="accordion-button reservation-btn" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseBasic" aria-expanded="true" aria-controls="collapseBasic">
                                   
                                    <div class="checkboxes__row">
                                        <div class="row">
                                            <div class="col-sm-8 width-60">
                                                <div class="sub-menu">
                                                <img src="@/assets/icons/white-checked.png" alt="">
                                            <p>Booking</p>
                                        </div>
                                            </div>
                                            <div class="col-sm-4 width-40">
                                                <span class="status-btn">Free</span>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="collapseBasi" class="accordion-collapse show" aria-labelledby="headingBasic"
                                data-bs-parent="#accordionExamplee">
                                <div class="accordion-body">
                                    <ul class="packages-">
                                        <li><span> <i class="fa-solid fa-check"></i></span> Reservations 24/7 (Nuba app)
                                        </li>
                                        <li class="disabled-menu"><span> <i
                                                    class="fa-solid fa-check"></i></span>Reservations (NubaBook)</li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Availability plan</li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Automatic confirmation</li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Manual waiting waitlist</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- table-accordian -->
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingBasic">
                                <button class="accordion-button reservation-btn" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseBasic" aria-expanded="true"
                                    aria-controls="collapseBasic">
                                   
                                    <div class="checkboxes__row">
                                        <div class="row">
                                            <div class="col-sm-8 width-60">
                                                <div class="sub-menu">
                                            <img src="@/assets/icons/disable-check.png" alt="">
                                        <p>Table</p>
                                        </div>
                                            </div>
                                            <div class="col-sm-4 width-40">
                                                <span class="status-btn ">+ $17</span>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="collapseBasc" class="accordion-collapse show"
                                aria-labelledby="headingBasic" data-bs-parent="#accordionExample">
                                <div class="accordion-body disable-menu-list">
                                    <ul class="packages-">
                                        <li><span> <i class="fa-solid fa-check"></i></span>Floors /Table </li>
                                        <li class="disabled-menu"><span> <i class="fa-solid fa-check"></i></span>Table
                                            plan </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Real-time spending alerts
                                        </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Real-time customer feedback
                                            and requests</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Analytics accordian -->
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingBasic">
                                <button class="accordion-button reservation-btn" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseBasic" aria-expanded="true"
                                    aria-controls="collapseBasic">

                                  

                                    <div class="checkboxes__row">
                                        <div class="row">
                                            <div class="col-sm-8 width-60">
                                                <div class="sub-menu">
                                                    <img src="@/assets/icons/white-checked.png" alt="">
                                            <p class="disabled-menu">Analytics</p>
                                        </div>
                                            </div>
                                            <div class="col-sm-4 width-40">
                                                <span class="status-btn disable-free">+ $12</span>
                                            </div>
                                        </div>
                                    </div>

                                </button>
                            </h2>
                            <div id="collapseBaic" class="accordion-collapse show"
                                aria-labelledby="headingBasic" data-bs-parent="#accordionExample">
                                <div class="accordion-body ">
                                    <ul class="packages-">
                                        <li><span> <i class="fa-solid fa-check"></i></span>Pos </li>
                                        <li class="disabled-menu"><span> <i class="fa-solid fa-check"></i></span>Table
                                            plan </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Payment </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Delivery </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Marketing </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Booking </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Robot </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Marketing -->
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingBasic">
                                <button class="accordion-button reservation-btn" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseBasic" aria-expanded="true"
                                    aria-controls="collapseBasic">
                                 

                                    <div class="checkboxes__row">
                                        <div class="row">
                                            <div class="col-sm-8 width-60">
                                                <div class="sub-menu">
                                            <img src="@/assets/icons/disable-check.png" alt="">
                                            <p class="disabled-menu">Marketing</p>
                                        </div>
                                            </div>
                                            <div class="col-sm-4 width-40">
                                                <span class="status-btn disable-free">+ $15</span>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="collapseBsic" class="accordion-collapse show"
                                aria-labelledby="headingBasic" data-bs-parent="#accordionExample">
                                <div class="accordion-body disable-menu-list">
                                    <ul class="packages-">
                                        <li><span> <i class="fa-solid fa-check"></i></span> Discount strategies </li>
                                        <li class="disabled-menu"><span> <i class="fa-solid fa-check"></i></span>Table
                                            plan </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span> Dating mode </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Story </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Event </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Gift </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Guests option  -->
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingBasic">
                                <button class="accordion-button reservation-btn" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseBasic" aria-expanded="true"
                                    aria-controls="collapseBasic">
                                   
                                    <div class="checkboxes__row">
                                        <div class="row">
                                            <div class="col-sm-8 width-60">
                                                <div class="sub-menu">
                                            <img src="@/assets/icons/disable-check.png" alt="">
                                            <p class="disabled-menu">Guests</p>
                                        </div>
                                            </div>
                                            <div class="col-sm-4 width-40">
                                                <span class="status-btn disable-free">+ $23</span>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="collapsBasic" class="accordion-collapse show"
                                aria-labelledby="headingBasic" data-bs-parent="#accordionExample">
                                <div class="accordion-body disable-menu-list">
                                    <ul class="packages-">
                                        <li><span> <i class="fa-solid fa-check"></i></span> guests data and profiles
                                        </li>
                                        <li class="disabled-menu"><span> <i class="fa-solid fa-check"></i></span>Chat
                                        </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span> Vip list </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Black list </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Staff -->
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingBasic">
                                <button class="accordion-button reservation-btn" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseBasic" aria-expanded="true"
                                    aria-controls="collapseBasic">
                                   

                                    <div class="checkboxes__row">
                                        <div class="row">
                                            <div class="col-sm-8 width-60">
                                                <div class="sub-menu">
                                            <img src="@/assets/icons/disable-check.png" alt="">
                                            <p class="disabled-menu">Staff</p>
                                        </div>
                                            </div>
                                            <div class="col-sm-4 width-40">
                                                <span class="status-btn disable-free">+ $23</span>
                                            </div>
                                        </div>
                                    </div>

                                </button>
                            </h2>
                            <div id="collapeBasic" class="accordion-collapse show"
                                aria-labelledby="headingBasic" data-bs-parent="#accordionExample">
                                <div class="accordion-body disabl-menu-list">
                                    <ul class="packages-">
                                        <li><span> <i class="fa-solid fa-check"></i></span>Add access </li>
                                        <li class="disabled-menu"><span> <i class="fa-solid fa-check"></i></span>
                                            Results and evaluation </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Menu -->
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingBasic">
                                <button class="accordion-button reservation-btn" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseBasic" aria-expanded="true"
                                    aria-controls="collapseBasic">
                                    <div class="checkboxes__row">
                                        <div class="row">
                                            <div class="col-sm-8 width-60">
                                                <div class="sub-menu">
                                            <img src="@/assets/icons/disable-check.png" alt="">
                                            <p class="disabled-menu">Menu</p>
                                        </div>
                                            </div>
                                            <div class="col-sm-4 width-40">
                                                <span class="status-btn disable-free">+ $10</span>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="collapeBasic" class="accordion-collapse show"
                                aria-labelledby="headingBasic" data-bs-parent="#accordionExample">
                                <div class="accordion-body disable-menu-list">
                                    <ul class="packages-">
                                        <li><span> <i class="fa-solid fa-check"></i></span>Personalise</li>
                                        <li class="disabled-menu"><span> <i class="fa-solid fa-check"></i></span>
                                            Promotions </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Stocks</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Integrations -->
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingBasic">
                                <button class="accordion-button reservation-btn" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseBasic" aria-expanded="true"
                                    aria-controls="collapseBasic">
                                    <div class="checkboxes__row">
                                        <div class="row">
                                            <div class="col-sm-8 width-60">
                                                <div class="sub-menu">
                                            <img src="@/assets/icons/disable-check.png" alt="">
                                            <p class="disabled-menu">Integrations</p>
                                        </div>
                                            </div>
                                            <div class="col-sm-4 width-40">
                                                <span class="status-btn">Free</span>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="collseBasic" class="accordion-collapse show"
                                aria-labelledby="headingBasic" data-bs-parent="#accordionExample">
                                <div class="accordion-body ">
                                    <ul class="packages-">
                                        <li><span> <i class="fa-solid fa-check"></i></span>Pos </li>
                                        <li class="disabled-menu"><span> <i class="fa-solid fa-check"></i></span>Payment
                                        </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Delivery </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Marketing </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Booking </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Robot </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Additional -->
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingBasic">
                                <button class="accordion-button reservation-btn" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseBasic" aria-expanded="true"
                                    aria-controls="collapseBasic">
                                    
                                    <div class="checkboxes__row">
                                        <div class="row">
                                            <div class="col-sm-8 width-60">
                                                <div class="sub-menu">
                                                    <img src="@/assets/icons/additional-icon.png" alt="">
                                            <p class="disabled-menu">Additional</p>
                                        </div>
                                            </div>
                                            <div class="col-sm-4 width-40">
                                                <span class="status-btn disable-free">+ $32</span>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="clapseBasic" class="accordion-collapse show"
                                aria-labelledby="headingBasic" data-bs-parent="#accordionExample">
                                <div class="accordion-body disable-menu-list">
                                    <ul class="packages- additiona-li">
                                        <li> - Sms notification <span class="status-btn ">+ $32</span>  <img src="@/assets/icons/white-checked.png" alt=""></li>
                                        <li class="disabled-menu"> - Online wait list <span class="status-btn ">+ $13</span>  <img src="@/assets/icons/disable-check.png" alt=""></li>
                                        <li> - Online payment <span class="status-btn ">+ $32</span>  <img src="@/assets/icons/white-checked.png" alt=""></li>
                                        <li> - Multiple locations <span class="status-btn ">+ $32</span>  <img src="@/assets/icons/white-checked.png" alt=""></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Premium  card -->
            <div class="card-content dark-card" data-aos="fade-up">
                <div class="plan-type">
                    <p>Premium </p>
                    <div class="min">
                        <p>{{ minDuration }}</p>
                    </div>
                </div>
                <div class="price">
                    <p> $59
                        <sup class="validity">{{ validity }}</sup>
                    </p>
                </div>
                <div class="yellow-button w-100 subscribe-btn">
                    Subscribe now
                </div>
                <p class="p-fade">{{ fadeText }}</p>
                <div class="cards-options">
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingPremium">
                                <button class="accordion-button reservation-btn" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapsePremium" aria-expanded="true" aria-controls="collapsePremium">
                                    <div class="checkboxes__row">
                                        <div class="row">
                                            <div class="col-sm-8 width-60">
                                                <div class="sub-menu">
                                                    <img src="@/assets/icons/white-checked.png" alt="">
                                            <p>Booking</p>
                                        </div>
                                            </div>
                                            <div class="col-sm-4 width-40">
                                                <span class="status-btn">Free</span>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="collapsePremiu" class="accordion-collapse show" aria-labelledby="headingPremium"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <ul class="packages-">
                                        <li><span> <i class="fa-solid fa-check"></i></span> Reservations 24/7 (Nuba app)
                                        </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Reservations (NubaBook)</li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Availability plan</li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Automatic confirmation</li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Manual waiting waitlist</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- table-accordian -->
                    <div class="accordion" id="table">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingPremium">
                                <button class="accordion-button reservation-btn" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapsePremium" aria-expanded="true"
                                    aria-controls="collapsePremium">
                                 
                                    
                                    <div class="checkboxes__row">
                                        <div class="row">
                                            <div class="col-sm-8 width-60">
                                                <div class="sub-menu">
                                            <img src="@/assets/icons/disable-check.png" alt="">
                                            <p>Table</p>
                                        </div>
                                            </div>
                                            <div class="col-sm-4 width-40">
                                                <span class="status-btn">+ $17</span>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="collapsePremim" class="accordion-collapse show"
                                aria-labelledby="headingPremium" data-bs-parent="#table">
                                <div class="accordion-body disable-menu-list">
                                    <ul class="packages-">
                                        <li><span> <i class="fa-solid fa-check"></i></span>Floors /Table </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Table
                                            plan </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Real-time spending alerts
                                        </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Real-time customer feedback
                                            and requests</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Analytics accordian -->
                    <div class="accordion" id="Analytics">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingPremium">
                                <button class="accordion-button reservation-btn" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapsePremium" aria-expanded="true"
                                    aria-controls="collapsePremium">
                                    
                                    <div class="checkboxes__row">
                                        <div class="row">
                                            <div class="col-sm-8 width-60">
                                                <div class="sub-menu">
                                                    <img src="@/assets/icons/white-checked.png" alt="">
                                            <p class="disabled-menu">Analytics</p>
                                        </div>
                                            </div>
                                            <div class="col-sm-4 width-40">
                                                <span class="status-btn disable-free">+ $12</span>
                                            </div>
                                        </div>
                                    </div>

                                </button>
                            </h2>
                            <div id="collapsePremum" class="accordion-collapse show"
                                aria-labelledby="headingPremium" data-bs-parent="#Analytics">
                                <div class="accordion-body ">
                                    <ul class="packages-">
                                        <li><span> <i class="fa-solid fa-check"></i></span>Pos </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Table
                                            plan </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Payment </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Delivery </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Marketing </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Booking </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Robot </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Marketing -->
                    <div class="accordion" id="Marketing">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingPremium">
                                <button class="accordion-button reservation-btn" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapsePremium" aria-expanded="true"
                                    aria-controls="collapsePremium">
                              
                                    <div class="checkboxes__row">
                                        <div class="row">
                                            <div class="col-sm-8 width-60">
                                                <div class="sub-menu">
                                            <img src="@/assets/icons/disable-check.png" alt="">
                                            <p class="disabled-menu">Marketing</p>
                                        </div>
                                            </div>
                                            <div class="col-sm-4 width-40">
                                                <span class="status-btn disable-free">+ $15</span>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="collapsePrmium" class="accordion-collapse show"
                                aria-labelledby="headingPremium" data-bs-parent="#Marketing">
                                <div class="accordion-body disable-menu-list">
                                    <ul class="packages-">
                                        <li><span> <i class="fa-solid fa-check"></i></span> Discount strategies </li>
                                        <li ><span> <i class="fa-solid fa-check"></i></span>Table
                                            plan </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span> Dating mode </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Story </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Event </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Gift </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Guests option  -->
                    <div class="accordion" id="Guests">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingPremium">
                                <button class="accordion-button reservation-btn" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapsePremium" aria-expanded="true"
                                    aria-controls="collapsePremium">
                                 
                                    
                                    <div class="checkboxes__row">
                                        <div class="row">
                                            <div class="col-sm-8 width-60">
                                                <div class="sub-menu">
                                            <img src="@/assets/icons/disable-check.png" alt="">
                                            <p class="disabled-menu">Guests</p>
                                        </div>
                                            </div>
                                            <div class="col-sm-4 width-40">
                                                <span class="status-btn disable-free">+ $23</span>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="collapPremium" class="accordion-collapse show"
                                aria-labelledby="headingPremium" data-bs-parent="#Guests">
                                <div class="accordion-body disable-menu-list">
                                    <ul class="packages-">
                                        <li><span> <i class="fa-solid fa-check"></i></span> guests data and profiles
                                        </li>
                                        <li ><span> <i class="fa-solid fa-check"></i></span>Chat
                                        </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span> Vip list </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Black list </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Staff -->
                    <div class="accordion" id="Staff">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingPremium">
                                <button class="accordion-button reservation-btn" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapsePremium" aria-expanded="true"
                                    aria-controls="collapsePremium">
                                    
                                    <div class="checkboxes__row">
                                        <div class="row">
                                            <div class="col-sm-8 width-60">
                                                <div class="sub-menu">
                                            <img src="@/assets/icons/disable-check.png" alt="">
                                            <p class="disabled-menu">Staff</p>
                                        </div>
                                            </div>
                                            <div class="col-sm-4 width-40">
                                                <span class="status-btn disable-free">+ $23</span>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="collapseemium" class="accordion-collapse show"
                                aria-labelledby="headingPremium" data-bs-parent="#Staff">
                                <div class="accordion-body disabl-menu-list">
                                    <ul class="packages-">
                                        <li><span> <i class="fa-solid fa-check"></i></span>Add access </li>
                                        <li ><span> <i class="fa-solid fa-check"></i></span>
                                            Results and evaluation </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Menu -->
                    <div class="accordion" id="Menu">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingPremium">
                                <button class="accordion-button reservation-btn" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapsePremium" aria-expanded="true"
                                    aria-controls="collapsePremium">

                                    
                                    <div class="checkboxes__row">
                                        <div class="row">
                                            <div class="col-sm-8 width-60">
                                                <div class="sub-menu">
                                            <img src="@/assets/icons/disable-check.png" alt="">
                                            <p class="disabled-menu">Menu</p>
                                        </div>
                                            </div>
                                            <div class="col-sm-4 width-40">
                                                <span class="status-btn disable-free">+ $10</span>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="collsePremium" class="accordion-collapse show"
                                aria-labelledby="headingPremium" data-bs-parent="#Menu">
                                <div class="accordion-body disable-menu-list">
                                    <ul class="packages-">
                                        <li><span> <i class="fa-solid fa-check"></i></span>Personalise</li>
                                        <li ><span> <i class="fa-solid fa-check"></i></span>
                                            Promotions </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Stocks</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Integrations -->
                    <div class="accordion" id="Integrations">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingPremium">
                                <button class="accordion-button reservation-btn" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapsePremium" aria-expanded="true"
                                    aria-controls="collapsePremium">
                                    <div class="checkboxes__row">
                                        <div class="row">
                                            <div class="col-sm-8 width-60">
                                                <div class="sub-menu">
                                                    <img src="@/assets/icons/white-checked.png" alt="">
                                            <p class="disabled-menu">Integrations</p>
                                        </div>
                                            </div>
                                            <div class="col-sm-4 width-40">
                                                <span class="status-btn">Free</span>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="clapsePremium" class="accordion-collapse show"
                                aria-labelledby="headingPremium" data-bs-parent="#Integrations">
                                <div class="accordion-body ">
                                    <ul class="packages-">
                                        <li><span> <i class="fa-solid fa-check"></i></span>Pos </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Payment
                                        </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Delivery </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Marketing </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Booking </li>
                                        <li><span> <i class="fa-solid fa-check"></i></span>Robot </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Additional -->
                    <div class="accordion" id="Additional">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingPremium">
                                <button class="accordion-button reservation-btn" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapsePremium" aria-expanded="true"
                                    aria-controls="collapsePremium">
                                    <div class="checkboxes__row">
                                        <div class="row">
                                            <div class="col-sm-8 width-60">
                                                <div class="sub-menu">
                                                    <img src="@/assets/icons/additional-icon.png" alt="">
                                                    <p class="disabled-menu">Additional</p>
                                        </div>
                                            </div>
                                            <div class="col-sm-4 width-40">
                                                <span class="status-btn disable-free">+ $32</span>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="collmium" class="accordion-collapse show"
                                aria-labelledby="headingPremium" data-bs-parent="#Additional">
                                <div class="accordion-body disable-menu-list">
                                    <ul class="packages- additiona-li">
                                        <li> - Sms notification <span class="status-btn ">+ $32</span>  <img src="@/assets/icons/white-checked.png" alt=""></li>
                                        <li> - Online wait list <span class="status-btn ">+ $13</span>  <img src="@/assets/icons/disable-check.png" alt=""></li>
                                        <li> - Online payment <span class="status-btn ">+ $32</span>  <img src="@/assets/icons/white-checked.png" alt=""></li>
                                        <li> - Multiple locations <span class="status-btn ">+ $32</span>  <img src="@/assets/icons/white-checked.png" alt=""></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>


    </div>
</template>
<script>
import AOS from 'aos';
import 'aos/dist/aos.css';
export default {
    props: {
        planType: {
            type: String,
            default: "Free plan"
        },
        minDuration: {
            type: String,
            default: "Min. 3 months"
        },
        price: {
            type: String,
            default: "$0"
        },
        validity: {
            type: String,
            default: "/40 operations"
        },
        buttonText: {
            type: String,
            default: "Start"
        },
        fadeText: {
            type: String,
            default: "Try our management system in action"
        },
        dropdownText: {
            type: String,
            default: "Reservation Package"
        },
        leadingIcon: {
            type: String,
            default: "apple.png"
        }
    },
    components: {

    },
    data() {
        return { selectedPlan: 'month', }
    },
    methods: {
        selectPlan(plan) {
            console.log(plan)
            this.selectedPlan = plan

        }
    },
    mounted() {
        AOS.init({
            duration: 800, // Animation duration
            offset: 200, // Offset (in px) from the original trigger point
            easing: 'ease', // Easing type
            once: false // Whether animation should only happen once
        });
    },
}
</script>
<style>
@import '/src/assets/css/common.css';

/* .disable-menu-list {
    display: none;
} */
.sub-menu{
    display: flex;
    align-items: center;
    gap: 3px;
}
.price-main-card{
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}
.additiona-li li{
    justify-content: space-between;
}
.checkboxes__row {
    /* display: flex; */
    align-items: center;
    justify-content: space-between;
    width: 85%;
}

.disable-free {
    background-color: transparent !important;
    border: 1px solid #8a8989;
    color: #8a8989 !important;
}

.disabled-menu {
    color: #8a8989 !important;
}

.cards-options {
    margin-top: 35px;
    height: 330px;
    overflow: auto;
    padding: 0 15px;
}

.status-btn {
    font-family: 'Epilogue Regular';
    color: white;
    font-weight: 300;
    font-size: 14px;
    background: #41403f;
    height: 27px;
    padding: 10px 1rem;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dark-card {
    background-color: #151413 !important;
    border: 1px solid #21201f;
}

.accordion-item {
    margin: 10px 0;
}

.subscribe-btn {
    background-color: #21201f;
    color: white;
    transition: 0.4s;
    font-family: 'Epilogue Regular';
}

.subscribe-btn:hover {
    background-color: rgb(90 90 90 / 50%);
    ;
}

.disable-btn {
    display: flex;
    background-color: #262524;
    border-radius: 9px;
    height: 52px;
    align-items: center;
    margin: 10px 0;
    padding: 0 20px;
}

.disable-btn i {
    color: #2e2d2c;
}

.tag {
    height: 40px !important;
    width: 40px !important;
}

.disable-btn p {
    color: #7a7979;
    margin: 0;
    margin-left: 10px;
    font-family: 'Epilogue Regular';
}

.form-group {
    display: block;
    margin-bottom: 15px;
}

.accordion-item {
    background-color: #1e1e1e;
    border: 1px solid #292929;
    border-radius: 24px;
}

.reservation-btn {
    background: #2c2b2a !important;
    border: none;
    height: 52px;
    padding: 0 10px;
    display: flex;
    align-items: center;
}

.reservation-btn p {
    margin: 0;
    font-family: 'Epilogue Regular';
    color: white;
    margin-left: 7px;
    font-size: 16px;
    font-weight: 400;
    margin-top: 2px;
}

.packages- {
    padding-left: 0;
}

.packages- li {
    color: white;
    list-style: none;
    font-family: 'Epilogue Regular';
    gap: 10px;
    font-size: 16px;
    height: 50px;
    line-height: 1.3;
    border-bottom: 1px solid #2c2c2c;
    display: flex;
    align-items: center;
}

.accordion-button:not(.collapsed) {
    box-shadow: none;
}

.reservation-btn:focus {
    box-shadow: none;
}

.accordion-button::after {
    filter: brightness(12.5) !important;
}

.form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.form-group label {
    position: relative;
    margin-left: 12px;
    cursor: pointer;
}

.form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #343434;
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    background: white;
    border-radius: 5px;
}

.form-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid black;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}


.tag i {
    transform: rotate(90deg);
}

.min {
    background-color: #2c2b2b;
    border-radius: 30px;
    padding: 8px 12px 8px 12px;
}

.min p {
    margin: 0;
    font-size: 15px;
}

.icon-image {
    height: 42px;
    width: 48px;
    border-radius: 8px;
    background: #373635;
    display: flex;
    justify-content: center;
    color: white;
    font-size: 20px;
    align-items: center;
}

.icon-image img {
    height: 100%;
    width: 100%;
}

.dd-button {
    display: flex;
    background-color: #2c2b2a;
    border-radius: 12px;
    font-size: 20px;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-top: 14px;
    padding: 8px;
    height: 55px;
    justify-content: space-between;
}

.dd-button p {
    color: #fff;
}

.leading-icon {
    height: 36px;
    width: 36px;
    padding: 8px;
}

.dd-item {
    color: #fff;
    font-family: 'Epilogue Regular';
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;

}

.card-content {
    background-color: rgba(255, 255, 255, 0.05);
    padding: 52px 22px 0;
    gap: 0px;
    border-radius: 24px;
    height: 725px;
    width: 413px;

    /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
}

.plan-type {
    display: flex;
    justify-content: space-between;
    color: white;

    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    font-family: 'Epilogue Regular';

}

.price {
    font-family: 'Epilogue Regular';
    font-size: 70px;
    font-weight: 400;
    line-height: 110px;
    text-align: left;
    color: #fff;


}

.price p {
    margin: 0;
    font-family: 'Epilogue Regular';
}

.validity {
    font-family: 'Epilogue Regular';
    font-size: 16px;
    font-weight: 400;
    line-height: 17.6px;
    text-align: left;
    color: #fff;
}

.p-fade {
    font-family: 'Epilogue Regular';
    font-size: 16px;
    font-weight: 400;
    margin-top: 12px;
    line-height: 22.4px;
    text-align: left;
    color: rgba(255, 255, 255, 0.5);

}

.dropdown-enter-active,
.dropdown-leave-active {
    transition: opacity 0.5s;
}

.dropdown-enter,
.dropdown-leave-to {
    opacity: 0;
}

@media (max-width: 575.98px) {
    .dd-button p {
        font-size: 15px;
    }

    .price p {
        font-size: 50px;
    }

    .validity {
        font-size: 14px;
    }

    .yellow-button {
        margin-top: 10px;
    }

    .min p {
        margin: 0;
        font-size: 13px;
    }
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background: #FFFFFF !important;
    color: black !important;
}

.nav-pills .nav-link {
    border-radius: var(--bs-nav-pills-border-radius);
    color: #908f8f;
    font-family: 'Epilogue Regular';
}

.switch-btn {
    background-color: #21201f;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-radius: 8px;
}

.switch-btn button {
    font-family: 'Epilogue Regular';
}

.b-price-container {
    display: flex;
    flex-direction: column;
    margin-top: 150px;

}

.b-heading {
    display: flex;
    justify-content: space-between;
}

.b-discount {
    font-family: Epilogue;
    font-size: 15px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
    color: #fff;
    margin-left: 8px;
    background-color: #B68D40;
    border-radius: 100px;
    padding: 5px 8px 5px 8px;

}

.b-heading-title {
    font-size: 40px;
    font-weight: 400;
    line-height: 62.4px;
    text-align: left;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Epilogue Regular';

}

.selected-button {
    color: #151413;
    font-family: Epilogue;
    font-size: 20px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
    background-color: #fff;
    padding: 20px 40px 20px 40px;
    border-radius: 12px;
    margin-right: 8px;
    margin-left: 8px;
    cursor: pointer;

}

.unselected-button {
    color: #FFFFFF80;
    font-family: Epilogue;
    font-size: 20px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
    padding: 20px 40px 20px 40px;
    cursor: pointer;
    /* margin-right: 8px; */

}

@media (max-width: 1199.98px) {
    .dd-button p {
        font-size: 15px;
    }
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {

    .b-heading-title {
        font-size: 30px;
    }

    .b-card-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .price p {
        font-size: 40px;
    }

    .dd-button p {
        font-size: 14px;
    }

    .b-price-container {
        margin-top: 0px;
    }
    .card-content{
        width: 350px;
        padding: 52px 10px 0;
    }
    .cards-options {
    padding: 0 6px;
}
.status-btn{
    padding: 10px 10px;
}
}

/* for mobile view */
@media (max-width: 575.98px) {
    .width-40{
        width: 40%;
    }
    .width-60{
        width: 60%;
    }
    .b-card-container {
        grid-template-columns: repeat(1, 1fr);
    }

    .b-heading {
        flex-wrap: wrap;
        justify-content: center;
    }

    .switch-btn {
        display: flex;
    }

    .b-price-container {
        margin-top: 0px;
    }

    .b-heading-title {

        font-size: 30px;
        line-height: 1.2;
    }
}
</style>